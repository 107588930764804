<template>
  <b-card>
    <!-- select 2 demo -->
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-select2
      variant="outline-primary"
    >
      Agregar Línea
    </b-button>
    <!-- select 2 demo -->
    <b-modal
      id="modal-select2"
      title="Agregar Línea"
      ok-title="submit"
      cancel-variant="outline-secondary"
      @ok="handleOk"
    >
      <b-form>
        <b-form-group label="Linea" label-for="linea">
          <b-form-input
            v-model="formLinea.linea"
            id="linea"
            placeholder="Ingrese una línea"
          />
        </b-form-group>
        <b-form-group label="Sub Línea" label-for="sublinea">
          <b-form-input
            v-model="formLinea.subLinea"
            id="sublinea"
            placeholder="Ingrese una  sub línea"
          />
        </b-form-group>
        <b-form-group label="Unidad/Preriodo" label-for="unidadperiodo">
          <b-form-input
            v-model="formLinea.unidadPerido"
            id="unidadperiodo"
            placeholder="Ingrese una Unidad/Periodo"
          />
        </b-form-group>
        <b-form-group label="Capacidad Periodo 1" label-for="capacidadperiodo1">
          <b-form-input
            v-model="formLinea.capacidadPeriodo_01"
            id="capacidadperiodo1"
            type="number"
            placeholder="Ingrese una Capacidad Periodo 1"
          />
        </b-form-group>
        <b-form-group
          label="Costo adicional / kg WFE 1"
          label-for="costoadicionalwfe1"
        >
          <b-form-input
            v-model="formLinea.costoAdicional_01"
            id="costoadicionalwfe1"
            type="number"
            placeholder="Ingrese una Costo Adicional /kg WFE 1"
          />
        </b-form-group>
        <b-form-group label="Capacidad Periodo 2" label-for="capacidadperiodo2">
          <b-form-input
            v-model="formLinea.capacidadPeriodo_02"
            id="capacidadperiodo2"
            type="number"
            placeholder="Ingrese una Capacidad Periodo 2"
          />
        </b-form-group>
        <b-form-group
          label="Costo adicional / kg WFE 2"
          label-for="costoadicionalwfe2"
        >
          <b-form-input
            v-model="formLinea.costoAdicional_02"
            id="costoadicionalwfe2"
            type="number"
            placeholder="Ingrese una Costo Adicional /kg WFE 2"
          />
        </b-form-group>

        <b-form-group label="Capacidad Periodo 3" label-for="capacidadperiodo3">
          <b-form-input
            v-model="formLinea.capacidadPeriodo_03"
            id="capacidadperiodo3"
            type="number"
            placeholder="Ingrese una Capacidad Periodo 3"
          />
        </b-form-group>

        <b-form-group
          label="Costo adicional / kg WFE 3"
          label-for="costoadicionalwfe3"
        >
          <b-form-input
            v-model="formLinea.costoAdicional_03"
            id="costoadicionalwfe3"
            type="number"
            placeholder="Ingrese una Costo Adicional /kg WFE 3"
          />
        </b-form-group>

        <b-form-group label="Capacidad Periodo 4" label-for="capacidadperiodo4">
          <b-form-input
            v-model="formLinea.capacidadPeriodo_04"
            id="capacidadperiodo4"
            type="number"
            placeholder="Ingrese una Capacidad Periodo 4"
          />
        </b-form-group>

        <b-form-group
          label="Costo adicional / kg WFE 4"
          label-for="costoadicionalwfe4"
        >
          <b-form-input
            v-model="formLinea.costoAdicional_04"
            id="costoadicionalwfe4"
            type="number"
            placeholder="Ingrese una Costo Adicional /kg WFE 4"
          />
        </b-form-group>

        <b-form-group label="Capacidad Periodo 5" label-for="capacidadperiodo5">
          <b-form-input
            v-model="formLinea.capacidadPeriodo_05"
            id="capacidadperiodo5"
            type="number"
            placeholder="Ingrese una Capacidad Periodo 5"
          />
        </b-form-group>

        <b-form-group
          label="Costo adicional / kg WFE 5"
          label-for="costoadicionalwfe5"
        >
          <b-form-input
            v-model="formLinea.costoAdicional_05"
            id="costoadicionalwfe5"
            type="number"
            placeholder="Ingrese una Costo Adicional /kg WFE 5"
          />
        </b-form-group>

        <b-form-group label="Capacidad Periodo 6" label-for="capacidadperiodo6">
          <b-form-input
            v-model="formLinea.capacidadPeriodo_06"
            id="capacidadperiodo6"
            type="number"
            placeholder="Ingrese una Capacidad Periodo 6"
          />
        </b-form-group>

        <b-form-group
          label="Costo adicional / kg WFE 6"
          label-for="costoadicionalwfe6"
        >
          <b-form-input
            v-model="formLinea.costoAdicional_06"
            id="costoadicionalwfe6"
            type="number"
            placeholder="Ingrese una Costo Adicional /kg WFE 6"
          />
        </b-form-group>

        <b-form-group label="Capacidad Periodo 7" label-for="capacidadperiodo7">
          <b-form-input
            v-model="formLinea.capacidadPeriodo_07"
            id="capacidadperiodo7"
            type="number"
            placeholder="Ingrese una Capacidad Periodo 7"
          />
        </b-form-group>

        <b-form-group
          label="Costo adicional / kg WFE 7"
          label-for="costoadicionalwfe7"
        >
          <b-form-input
            v-model="formLinea.costoAdicional_07"
            id="costoadicionalwfe7"
            type="number"
            placeholder="Ingrese una Costo Adicional /kg WFE 7"
          />
        </b-form-group>

        <b-form-group label="Capacidad Periodo 8" label-for="capacidadperiodo8">
          <b-form-input
            v-model="formLinea.capacidadPeriodo_08"
            id="capacidadperiodo8"
            type="number"
            placeholder="Ingrese una Capacidad Periodo 8"
          />
        </b-form-group>

        <b-form-group
          label="Costo adicional / kg WFE 8"
          label-for="costoadicionalwfe8"
        >
          <b-form-input
            v-model="formLinea.costoAdicional_08"
            id="costoadicionalwfe8"
            type="number"
            placeholder="Ingrese una Costo Adicional /kg WFE 8"
          />
        </b-form-group>

        <b-form-group label="Capacidad Periodo 9" label-for="capacidadperiodo9">
          <b-form-input
            v-model="formLinea.capacidadPeriodo_09"
            id="capacidadperiodo9"
            type="number"
            placeholder="Ingrese una Capacidad Periodo 9"
          />
        </b-form-group>

        <b-form-group
          label="Costo adicional / kg WFE 9"
          label-for="costoadicionalwfe9"
        >
          <b-form-input
            v-model="formLinea.costoAdicional_09"
            id="costoadicionalwfe9"
            type="number"
            placeholder="Ingrese una Costo Adicional /kg WFE 9"
          />
        </b-form-group>
        <!--
        <b-form-group
          label="Choose the country"
          label-for="vue-select"
        >
          <v-select
            id="vue-select"
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="option"
          />
        </b-form-group>
        <b-form-group
          label="Zip Code"
          label-for="zip-code"
        >
          <b-form-input
            id="zip-code"
            type="number"
            placeholder="Zip Code"
          />
        </b-form-group>
        -->
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
  },
  props: {
    rowData: [],
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      formLinea: {},
    };
  },
  methods: {
    handleOk(bvModalEvt) {
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.rowData.push(this.formLinea);

      this.formLinea = {};

      this.$nextTick(() => {
        this.$bvModal.hide();
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.card {
  margin-bottom: 0rem;
}
</style>
