<template>
    <b-card-code :title="title">

            <b-row class="match-height d-md-flex">
                <b-col md="6" class="d-md-flex">
                    <modal-form-scroll :rowData="rows" />
                </b-col>
                <b-col md="6" class="d-md-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Buscar:</label>
                            <b-input-group class="input-group-merge">
                            <b-form-input
                                v-model="searchTerm"
                                placeholder="Buscar"
                                type="text"
                                class="d-inline-block"
                                n
                            />
                             <b-input-group-append is-text>
                                <feather-icon
                                icon = "EyeIco"
                                class="cursor-pointer"
                                />
                            </b-input-group-append>
                            </b-input-group>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
       

        <!-- table -->
        <vue-good-table
            :columns="dataTable.columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm
            }"
            :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
                enabled: true,
                perPage: pageLength
            }"
        >
            <template
                slot="table-row"
                slot-scope="props"
                v-if="dataTable.verDetalle"
            >
                <!-- Column: Action -->
                <span v-if="props.column.field === 'detalle'">
                    <span>
                        <b-button
                            variant="outline-primary"
                            :to="{ name: 'dashboard-blumar-comercial' }"
                        >
                            Ver detalle
                        </b-button>
                    </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                            Mostrando 1 a
                        </span>
                        <b-form-select
                            v-model="pageLength"
                            :options="['3', '5', '10']"
                            class="mx-1"
                            @input="
                                value =>
                                    props.perPageChanged({
                                        currentPerPage: value
                                    })
                            "
                        />
                        <span class="text-nowrap">
                            de {{ props.total }} entradas
                        </span>
                    </div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="
                                value =>
                                    props.pageChanged({ currentPage: value })
                            "
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-card-code>
</template>

<script>
import ModalFormScroll from './ModalFormScroll.vue'
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCol,
    BRow,
    BInputGroupAppend,
    BInputGroup
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";

export default {
    components: {
        BCardCode,
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BButton,
        BCol,
        BRow,
        BInputGroupAppend,
        BInputGroup,
        ModalFormScroll
    },
    props: {
        title: {
            type: String,
            default: ""
        },
        dataTable: {
            type: Object,
            default: ""
        }
    },
    data() {
        return {
            pageLength: 10,
            dir: false,

            rows: [],
            searchTerm: "",
            status: [
                {
                    1: "Current",
                    2: "Professional",
                    3: "Rejected",
                    4: "Resigned",
                    5: "Applied"
                },
                {
                    1: "light-primary",
                    2: "light-success",
                    3: "light-danger",
                    4: "light-warning",
                    5: "light-info"
                }
            ]
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Current: "light-primary",
                Professional: "light-success",
                Rejected: "light-danger",
                Resigned: "light-warning",
                Applied: "light-info"
                /* eslint-enable key-spacing */
            };

            return status => statusColor[status];
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true;
                return this.dir;
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false;
            return this.dir;
        }
    },
    created() {
        this.rows = this.dataTable.planning;
    },
    methods: {
        randomDate(date1, date2) {
            function getRandomArbitrary(min, max) {
                return Math.random() * (max - min) + min;
            }
            var date1 = date1 || "01-01-1970";
            var date2 = date2 || new Date().toLocaleDateString();
            date1 = new Date(date1).getTime();
            date2 = new Date(date2).getTime();
            if (date1 > date2) {
                return new Date(
                    getRandomArbitrary(date2, date1)
                ).toLocaleDateString();
            } else {
                return new Date(
                    getRandomArbitrary(date1, date2)
                ).toLocaleDateString();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-good-table.scss";

.icon-code {
    display: none !important;
}

span{
    font-size: 14px !important;
}
</style>
